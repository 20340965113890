<template>
  <v-form
    ref="form"
    v-model="valid"
    class="rcc-return-points-form"
    @submit.prevent="onSubmitForm"
  >
    <div class="rcc-return-points-form__form-body">
      <div class="column left">
        <rcc-text-input
          label="Название"
          :is-disabled="form.is_imported"
          class="field"
          v-model="form.name"
          :rules="[value => form.is_imported || isRequiredField({ value, field: 'Название' })]"
        />

        <rcc-number-input
          v-if="belongsToX5OrImportedFromPortal"
          :is-disabled="form.is_imported"
          label="Номер с портала"
          :min="1"
          class="field"
          v-model="form.number"
          :rules="[value => isRequiredField({ value, field: 'Номер с портала' })]"
        />

        <rcc-select
          label="Сеть"
          class="field"
          :is-disabled="form.is_imported"
          :items="networksOptions"
          v-model="form.network_id"
          :rules="[value => isRequiredField({ value, errorText: 'Выберите сеть' })]"
        />

        <rcc-select
          v-if="belongsToX5OrImportedFromPortal"
          label="Регион"
          class="field"
          :items="regionsOptions"
          v-model="form.region_id"
          :rules="[value => isRequiredField({ value, errorText: 'Выберите регион' })]"
        />

        <rcc-text-input
          label="Адрес"
          class="field"
          v-model="form.address"
          :rules="[value => form.is_imported || isRequiredField({ value, field: 'Адрес' })]"
        />

        <rcc-select
          label="Юр. лицо"
          class="field"
          :items="legalEntitiesOptions"
          v-model="form.legal_entity_id"
          :rules="[value => isRequiredField({ value, errorText: 'Выберите юридическое лицо' })]"
        />

        <rcc-text-input
          label="Контакты"
          class="field"
          v-model="form.contacts"
          :rules=[contactsValidation]
        />

        <rcc-select
          label="Федеральный округ"
          class="field"
          :items="districts"
          show-by="name"
          v-model="form.district_id"
        />
      </div>

      <div class="column right">
        <!-- <rcc-contacts v-model="form.contacts" /> -->

        <rcc-textarea
          v-model="form.note"
          label="Примечание"
          class="field"
        />

        <section class="rcc-return-points-form__form-section">
          <h2>Время подачи ТС</h2>

          <div class="time-wrapper">
            <rcc-time-input
              v-model="startTime"
              class="time-input"
              label="от"
              :max="endTime"
              :rules="[startTimeValidation]"
            />

            <rcc-time-input
              v-model="endTime"
              class="time-input"
              label="до"
              :min="startTime"
              :rules="[endTimeValidation]"
            />
          </div>
        </section>

        <section v-if="belongsToX5OrImportedFromPortal" class="booking-section">
          <h2>Бронирование</h2>

          <rcc-number-input
            label="min кол-во паллет"
            :min="1"
            class="field"
            v-model="form.intermediate_booking_min_pallet"
          />

          <rcc-number-input
            class="field number-input"
            label="Оптимальное кол-во паллет"
            :rules="[bookingSoftLimitValidation]"
            v-model="form.consolidation_booking_soft_limit_pallet"
          />

          <rcc-number-input
            label="max кол-во паллет"
            :min="1"
            class="field"
            v-model="form.intermediate_booking_max_pallet"
          />
        </section>
      </div>
    </div>

    <rcc-confirm-dialog
      :is-show.sync="isShowConfirmDialog"
      :title="blockText.title"
      :text="blockText.text"
      :confirmCallback="pointChangeStatus"
    />

    <rcc-footer-buttons
      :is-submit="isSubmit"
      :waningText="warningButtonText"
      @warning-click="handleBlockClick"
      @cancel-click="$router.push({
        name: 'return-points',
        params: { tradingNetworkId: $route.params.tradingNetworkId }
      })"
    />
  </v-form>
</template>

<script>
import { getNetworksList } from '@/api/trading-networks'
import { getLegalEntities } from '@/api/legal-entities'
import { getOptions } from '@/utils/get-options'
import ReturnPointApi from '@/api/return-points'
import { timeDifference } from '@/utils/time'
import Page from '@/mixins/page'
import Form from '@/mixins/form'

import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccTextInput from 'Components/controls/rcc-text-input'
import RccTimeInput from 'Components/controls/rcc-time-input'
import RccTextarea from 'Components/controls/rcc-textarea'
import RccSelect from 'Components/controls/rcc-select'
import RccNumberInput from 'Components/controls/rcc-number-input'
// import RccContacts from '@/components/ui/contacts'
import RccConfirmDialog from 'Components/dialogs/rcc-confirm-dialog'

export default {
  components: {
    RccTextInput,
    RccFooterButtons,
    RccSelect,
    RccTimeInput,
    RccTextarea,
    RccNumberInput,
    // RccContacts,
    RccConfirmDialog
  },

  mixins: [Page, Form, ReturnPointApi],

  data() {
    return {
      startTime: null,
      endTime: null,
      legalEntitiesOptions: [],
      networksOptions: [],
      regionsOptions: [],
      distributionCentersOptions: [],
      isShowConfirmDialog: false,
      districts: [],

      form: {
        name: '',
        address: '',
        network_id: null,
        legal_entity_id: null,
        delivery: null,
        contacts: '',
        // contacts: {},
        note: '',
        intermediate_booking_min_pallet: null,
        consolidation_booking_soft_limit_pallet: null,
        intermediate_booking_max_pallet: null
      }
    }
  },

  computed: {
    belongsToX5OrImportedFromPortal() {
      return this.form.network_id === this.$constants.networkIds.x5 || this.form.is_imported
    },

    selects() {
      return [
        {
          method: getLegalEntities,
          target: 'legalEntitiesOptions',
          errorMessage: 'Не удалось загрузить данные для юр. лиц'
        },
        {
          method: getNetworksList,
          target: 'networksOptions',
          errorMessage: 'Не удалось загрузить данные для сетей'
        },
        {
          method: this.$apiMethods.regions.allList,
          target: 'regionsOptions',
          errorMessage: 'Не удалось загрузить данные для регионов'
        }
      ]
    },

    deliveryTime() {
      return [this.startTime, this.endTime]
    },

    isDeliveryTimesEmpty() {
      return !this.startTime && !this.endTime
    }
  },

  watch: {
    'form.region_id'(id) {
      if (!id || (this.form.intermediate_booking_min_pallet && this.form.intermediate_booking_max_pallet)) {
        return
      }

      this.$apiMethods.regions.get(id)
        .then(({ intermediate_booking_min_pallet, intermediate_booking_max_pallet }) => {
          if (!this.form.intermediate_booking_min_pallet) {
            this.form.intermediate_booking_min_pallet = intermediate_booking_min_pallet
          }

          if (!this.intermediate_booking_max_pallet) {
            this.form.intermediate_booking_max_pallet = intermediate_booking_max_pallet
          }
        })
    }
  },

  created() {
    getOptions.bind(this)(this.selects)

    this.$apiMethods.districts.list()
      .then(data => {
        this.districts = data
      })
  },

  methods: {
    bookingSoftLimitValidation(value) {
      return value > this.form.intermediate_booking_min_pallet
        && value < this.form.intermediate_booking_max_pallet
        || 'Должно быть больше min кол-ва паллет и меньше max кол-ва паллет'
    },

    contactsValidation(value) {
      if (!value) {
        return 'Поле Контакты не может быть пустым'
      }
      return value.length <= 200 || 'Текст не может быть длиннее 200 символов'
    },

    startTimeValidation(value) {
      if(this.isDeliveryTimesEmpty) {
        return true
      }

      return !this.endTime ||
        (
          timeDifference(this.endTime, value, 'minutes') >= 0 ||
            'Не может быть больше ' + this.endTime
        )
    },

    endTimeValidation(value) {
      if(this.isDeliveryTimesEmpty) {
        return true
      }

      return !this.startTime ||
        (
          timeDifference(value, this.startTime, 'minutes') >= 0 ||
            'Не может быть меньше ' + this.startTime
        )
    },

    getOptions() {
      this.getLegalEntities().then(({ items }) => {
        this.legalEntities = items
      })
    },

    beforeSubmit() {
      const formData = { ...this.form, delivery: this.deliveryTime }

      if (!this.belongsToX5OrImportedFromPortal) {
        formData.number = null
        formData.region_id = null
        formData.intermediate_booking_min_pallet = null
        formData.intermediate_booking_max_pallet = null
      }

      return formData
    },

    pointChangeStatus() {},
    handleBlockClick() {}
  }
}
</script>

<style lang="scss" scoped>
.rcc-return-points-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form-body {
    flex-grow: 1;
    padding: $base-form-padding;
    display: flex;
    gap: 50px;

    @media screen and (max-width: $screen-md) {
      justify-content: space-between;
    }

    @media screen and (max-width: $screen-sm) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .column {
    width: 400px;

    @media screen and (max-width: $screen-md) {
      width: 100%;
    }
  }

  .field {
    width: 100%;
  }

  &__form-section {
    margin: 10px 0;
  }

  .time-wrapper {
    width: 100%;
    display: flex;
    gap: 20px;
  }

  .time-input {
    width: 100%;
  }

  section {
    margin-top: 2px;
    margin-bottom: 0;
    h2 {
      margin-top: 0;
    }
  }
}
</style>
